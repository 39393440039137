@keyframes blink {
  0% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}


.player {
  animation: blink 2.5s infinite;
  animation-fill-mode: both;
  background: none;
  width: 80px;
  height: 80px;
  border: solid 5px #fff;
  border-radius: 100%;
  position: relative;
  text-indent: -9999px;
  box-shadow: 1px 1px 3px #999999;
}

.player span {
  width: 0;
  height: 0;
  border-top: 23px solid transparent;
  border-left: 30px solid #fff;
  border-bottom: 23px solid transparent;
  position: absolute;
  top: 20%;
  left: 36%;
}

.player:hover {
  background: #B6ACA6;
  cursor: pointer;
  opacity: 0.8;
  animation: infinite;
  animation-fill-mode: both;
  border: none;
  width: 80px;
  height: 80px;
  box-shadow: none;
}

.player:hover span {
  top: 25%;
  left: 38%;
}